<template>
  <div id="producer-public-profile">
    <QsCard
      :qlik-ids="['mLDTu']"
      style="grid-column: 1 / span 24"
      card-style="compact"
    />

    <QsCard :qlik-ids="['AbecP']" style="grid-column: 1 / span 6" />

    <QsCard :qlik-ids="['JAsu']" style="grid-column: 7 / span 6" />

    <QsCard :qlik-ids="['RFmpkk']" style="grid-column: 13 / span 12" />

    <QsCard
      :qlik-ids="['UnyBRU']"
      style="grid-column: 1 / span 12; grid-row: 3 / span 1"
    />

    <QsTabs
      :qlik-ids="['NpsfZT', 'zRtQ']"
      style="grid-column: 13 / span 12; grid-row: 3 / span 2"
    />

    <QsCard
      :qlik-ids="['juHeq']"
      style="grid-column: 1 / span 12; grid-row: 4 / span 1"
    />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";
import QsTabs from "@/components/Qlik/QsTabs";

export default {
  name: "ProducerPublicProfile",
  components: { QsCard, QsTabs }
};
</script>

<style scoped>
#producer-public-profile {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 45px 330px repeat(2, 300px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
